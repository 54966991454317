
import { Vue, Component, Watch } from 'vue-property-decorator'
import { DoctorInfo } from '@/components'
import { ClinicAPI } from '@/api'
import globalSetting from '@/utils/globalSetting'

@Component({
  components: {
    DoctorInfo
  }
})
export default class DoctorList extends Vue {

}
